import { AI } from "./components/AI";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { SoftEng } from "./components/SoftEng";
import { Testimony } from "./components/Testimony";
import { Title } from "./components/Title";
import { WebDev } from "./components/WebDev";

export const App = () => {
  return (
    <div className="App">
      <div className="container mx-auto xl:w-1/2 px-2">
        <div className="h-screen flex flex-col justify-between">
        <Header />

        <Title />

        <Testimony />
        </div>


        <SoftEng />
        <AI />
        <WebDev />

        <Contact />

        <Footer />
      </div>
    </div>
  );
};
