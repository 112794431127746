export const AI = () => {
  return (
    <div id="ai">
      <div className="grid grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 mt-24 lg:mt-32 items-center">
        <div>
          <div className="text-3xl xl:text-4xl font-light text-gradient py-1 text-center mb-2 lg:text-left">
            Aritifical Intelligence
          </div>
          <div className="text-lg lg:text-lg text-fg-dark font-light text-center lg:text-left">
            Need to make your apps smarter? We provide a wealth of AI services
            such as Natural Language Processing, Computer Vision and anomaly
            detection.
          </div>
        </div>
        <div className="w-4/5 lg:w-5/5 mx-auto">
          <video loop autoPlay muted>
            <source src="/videos/taranis-ai.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};
