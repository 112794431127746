import { ReactComponent as MainSvg } from "../images/taranis-softeng-01.svg";
import { ReactComponent as PackageSvg } from "../images/taranis-softeng-02.svg";

export const SoftEng = () => {
  return (
    <div id="software">
      <div className="grid grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 mt-8 lg:mt-32 items-center">

        <div className="row-start-2 lg:col-start-1 lg:row-start-1 w-1/2 lg:w-3/5 mx-auto mt-2">
          <video loop autoPlay muted>
            <source src="/videos/taranis-softeng.mp4" type="video/mp4" />
          </video>
        </div>

        <div className="row-start-1 lg:col-start-2 lg:row-start-1">
          <div className="text-3xl xl:text-4xl font-light text-gradient py-1 mb-2 text-center lg:text-left">
            Software Engineering
          </div>
          <div className="text-lg lg:text-lg text-fg-dark font-light text-center lg:text-left">
            We provide a variety of Software services ranging from native
            applications to cloud computing applications (AWS, GCP, Azure).
          </div>
        </div>
      </div>
    </div>
  );
};
