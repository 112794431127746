export const Title = () => {
  return (
    <div className="grid grid-cols-3 lg:grid-cols-2">
      <div className="text-right z-10 col-span-2 lg:col-span-1 py-10">
        <div className="text-3xl md:text-5xl xl:text-6xl font-light text-fg-dark translate-x-4 lg:translate-x-24 inline-block text-left">
          Computing Services.
          <br />
          <span className="text-gradient title-typed"></span>
        </div>
      </div>
      <div className="polka -translate-x-24 lg:-translate-x-24 lg:w-4/5"></div>
    </div>
  );
};
