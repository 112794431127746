import { HashLink } from "react-router-hash-link";

export const Header = () => {
  return (
    <div className="select-none flex flex-row w-full mt-5 px-2 items-center">
      <div className="text-fg-dark text-xl font-normal text-gradient montserrat cursor-pointer tracking-widest">
        TARANIS
      </div>
      <div className="flex flex-row justify-end ml-auto font-light text-md items-center">
        <HashLink
          to="#software"
          smooth
          className="mr-2 text-sm lg:text-lg lg:mr-3 cursor-pointer text-fg-dark hover:text-fg-secondary-dark"
        >
          Software
        </HashLink>
        <HashLink
          to="#ai"
          smooth
          className="mr-2 text-sm lg:text-lg lg:mr-3 cursor-pointer text-fg-dark hover:text-fg-secondary-dark"
        >
          AI
        </HashLink>
        <HashLink
          to="#webdev"
          smooth
          className="mr-2 text-sm lg:text-lg lg:mr-5 cursor-pointer text-fg-dark hover:text-fg-secondary-dark"
        >
          Web
        </HashLink>
        <div className="group cursor-pointer bg-gradient hover:bg-none hover:bg-fg-dark px-0.5 py-1 transition-colors duration-200">
          <HashLink
            to="#contact"
            smooth
            className="text-fg-dark bg-bg-dark px-2 py-1 group-hover:text-bg-dark group-hover:bg-fg-dark transition-colors duration-200"
          >
            Contact
          </HashLink>
        </div>
      </div>
    </div>
  );
};
