export const Contact = () => {
  return (
    <div id="contact" className="flex flex-col mt-12 lg:mt-32 bg-gradient p-5 stripe">
      <div className="text-center text-fg-dark text-4xl font-bold">Contact</div>
      <div className="mt-6">
        <div className="text-fg-dark text-xl text-center mt-2">
          William Profit
        </div>
        <div className="text-fg-dark text-xl text-center mt-2">
          France / United Kingdom
        </div>
        <div className="text-fg-dark text-xl text-center mt-2">
          +33 652 817 026
        </div>
        <div className="text-fg-dark text-xl text-center mt-2">
          williamprofit@hotmail.fr
        </div>
      </div>
    </div>
  );
};
