export const WebDev = () => {
  return (
    <div id="webdev">
      <div className="grid grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 mt-16 lg:mt-32 items-center">
        <div className="w-1/2 lg:w-3/5 mx-auto row-start-2 lg:col-start-1 lg:row-start-1">
          <video loop autoPlay muted>
            <source src="/videos/taranis-webdev.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="row-start-1 lg:col-start-2 lg:row-start-1">
          <div className="text-3xl xl:text-4xl font-light text-gradient py-1 text-center mb-2 lg:text-left">
            Web Development
          </div>
          <div className="text-lg lg:text-lg text-fg-dark font-light text-center lg:text-left">
            Let us help you show your projects to the world with custom built websites. Need to add a database and manage users? We've got you covered.
          </div>
        </div>
      </div>
    </div>
  );
};