export const Testimony = () => {
  return (
    <div className="flex flex-col bg-bg-secondary-dark px-8 py-5 stripe mb-10">
      <div className="text-xl lg:text-2xl text-gradient mb-5 font-light">
        Hear what our clients say.
      </div>
      <div className="italic text-lg lg:text-xl text-fg-dark font-light">
        "We were able to build new projects despite not having the internal
        capabilities all while meeting deadlines before they were due."
      </div>
      <div className="mt-2 text-md text-fg-secondary-dark">
        <a
          className="hover:underline"
          href="https://www.labservice.com/"
          target="_blank"
          rel="noreferrer"
        >
          - Lab Service SAS
        </a>
      </div>
    </div>
  );
};
