export const Footer = () => {
  return (
    <div className="mt-10 mb-2">
      <div className="flex flex-row">
      </div>
      <div className="text-xs lg:text-sm text-fg-secondary-dark text-center mb-4">
        &copy; 2022 Taranis Technologies. All Rights Reserved.
      </div>
    </div>
  )
}